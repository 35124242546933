import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatSnackBarLabel, MatSnackBarActions, MatSnackBarAction, MatSnackBarRef, MAT_SNACK_BAR_DATA
} from '@angular/material/snack-bar';
import { SnackBarData } from '@core/models';

@Component({
  selector: 'app-error-snackbar',
  standalone: true,
  imports: [MatButtonModule, MatSnackBarLabel, MatSnackBarActions, MatSnackBarAction],
  templateUrl: './error-snackbar.component.html',
  styleUrl: './error-snackbar.component.scss'
})
export class ErrorSnackbarComponent {
  data = inject<SnackBarData>(MAT_SNACK_BAR_DATA);
  snackBarRef = inject(MatSnackBarRef);
}
