import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

import {
  Document,
  Member,
  ProgressIterable,
  ProgressUpdate,
  Workspace,
  WorkspaceAccess,
  WorkspaceDocument,
  WorkspaceOperation,
} from '@core/models';
import { AdminApiService } from './admin-api.service';
import { PopulateResult, PopulateService } from './populate.service';
import { DocumentTypes, LevelPolicy, RecordTypes } from '@shared/reference';
import { DocumentApiService } from './document-api.service';
import { MemberService } from './member.service';
import jsonpatch from 'json-patch';
import { IdentityService } from './identity.service';

export class CreateWorkspaceResult {
  constructor(public workspace: Workspace, public createdRecordTypes: string[]) { }
}

@Injectable({
  providedIn: 'root'
})
export class WorkspaceService {

  constructor(
    private readonly adminApiService: AdminApiService,
    private readonly identityService: IdentityService,
    private readonly documentApiService: DocumentApiService,
    private readonly memberService: MemberService,
    private readonly populateService: PopulateService,
  ) { }

  /**
   * Convert a workspace to a workspace document
   * @param workspace workspace to convert
   * @returns workspace document
   */
  convertWorkspaceToWorkspaceDocument(workspace: Workspace): WorkspaceDocument {
    return {
      id: workspace.workspaceId,
      documentType: DocumentTypes.workspace,
      content: {
        displayName: workspace.displayName,
        uriName: workspace.uriName,
        description: workspace.description || '',
        type: workspace.isReference ? 'template' : 'app',
        isEnabled: true,
        tier: 'base',
        templateId: workspace.templateId,
        isReference: workspace.isReference || false,
        workspaceAdminLimit: workspace.workspaceAdminLimit,
        workspaceStandardLimit: workspace.workspaceStandardLimit,
        publicWorkspacePolicy: workspace.publicWorkspacePolicy,
        members: workspace.members.map(m => ({
          id: m.memberId,
          workspacePolicy: m.workspacePolicy
        })),
        ownerMemberId: workspace.ownerMemberId,
        tags: workspace.tags || [],
      },
    };
  }

  /**
   * Convert a workspace document to a workspace
   * @param workspaceDocument workspace document to convert
   * @returns workspace
   */
  convertWorkspaceDocumentToWorkspace(workspaceDocument: WorkspaceDocument): Workspace {
    return {
      workspaceId: workspaceDocument.id,
      displayName: workspaceDocument.content.displayName,
      uriName: workspaceDocument.content.uriName,
      description: workspaceDocument.content.description,
      templateId: workspaceDocument.content.templateId,
      isEnabled: workspaceDocument.content.isEnabled,
      isReference: workspaceDocument.content.isReference,
      workspaceAdminLimit: workspaceDocument.content.workspaceAdminLimit,
      workspaceStandardLimit: workspaceDocument.content.workspaceStandardLimit,
      publicWorkspacePolicy: workspaceDocument.content.publicWorkspacePolicy,
      members: workspaceDocument.content.members.map(m => ({
        memberId: m.id,
        workspacePolicy: m.workspacePolicy
      })),
      ownerMemberId: workspaceDocument.content.ownerMemberId,
      tags: workspaceDocument.content.tags,
    };
  }

  /**
   * Get a workspace by its owner
   * @param memberId owner member id
   * @returns The first workspace found, or null if the owner does not have a workspace
   */
  async getWorkspaceByOwner(memberId: string): Promise<Workspace | null> {
    const [workspaces, _] = await this.adminApiService.getWorkspaces(`ownerMemberId=${memberId}`);
    return workspaces.length > 0 ? this.convertWorkspaceDocumentToWorkspace(workspaces[0]) : null;
  }

  /**
   * Create a workspace in the platform
   * @param workspaceToCreate The workspace to create
   * @param members The embers to add to the workspace
   * @returns The created workspace
   */
  async *createWorkspace(
    workspaceToCreate: Workspace,
    members: Member[]): ProgressIterable<CreateWorkspaceResult> {

    yield new ProgressUpdate(0, `Creating workspace`);
    // Create workspace
    const workspace = this.convertWorkspaceDocumentToWorkspace(
      await this.adminApiService.createWorkspace(
        this.convertWorkspaceToWorkspaceDocument(workspaceToCreate)));

    yield new ProgressUpdate(30, 'Refreshing your access');
    await this.identityService.refreshClaims();

    yield new ProgressUpdate(50, 'Populating workspace');
    let createdRecordTypes: string[] = [];
    for await (const result of this.populateService.populate(
      workspace.workspaceId,
      workspace.templateId || '',
      workspace.displayName)) {
        if (result instanceof PopulateResult) {
          createdRecordTypes = result.recordTypes;
          break;
        }
        if (result instanceof ProgressUpdate) {
          yield new ProgressUpdate(50 + result.value * 0.4, result.message);
        }
    }

    if (!createdRecordTypes) {
      // Could not populate default content, disable workspace
      const instructions: jsonpatch.OpPatch[] = [
        { op: 'replace', path: '/isEnabled', value: false },
        { op: 'replace', path: '/displayName', value: workspace.displayName + '-fail' },
        { op: 'replace', path: '/uriName', value: workspace.uriName + '-fail' },
      ];
      yield new ProgressUpdate(90, 'Population failed: Disabling workspace');
      await this.adminApiService.patchWorkspace(instructions, workspace.workspaceId);
      yield new ProgressUpdate(100, 'Workspace creation cancelled');
      throw new Error('Populating default content failed after retries');
    }

    yield new ProgressUpdate(70, 'Creating new members');
    await this.memberService.createMissingMembers(members);

    yield new ProgressUpdate(100, 'Workspace created');
    yield new CreateWorkspaceResult(workspace, createdRecordTypes);
  }

  /**
   * Update the member's access in the specified workspaces
   * @param memberId The member to update in the workspaces
   * @param workspaces The original workspaces
   * @param workspaceAccesses The new workspace accesses for each workspace
   */
  async *updateMemberAccessInWorkspaces(
    memberId: string,
    workspaces: Workspace[], // Original workspaces
    workspaceAccesses: WorkspaceAccess[], // New workspace accesses
  ): ProgressIterable<void> {

    // Remove member access in workspaces
    let progressMultiplier = 50 / workspaces.length;

    for (const workspace of workspaces) {
      const hasAccess = workspaceAccesses.some(wa => wa.workspaceId === workspace.workspaceId);

      if (hasAccess) {
        // Do not remove member access if the member has access
        continue;
      }

      const members = workspace.members
        .filter(m => m.memberId !== memberId)
        .map(m => ({
          id: m.memberId,
          workspacePolicy: m.workspacePolicy,
        }));

      // Validate workspace policy limits
      const updatedWorkspace = { ...workspace };
      updatedWorkspace.members = members.map(m => ({
        memberId: m.id,
        workspacePolicy: m.workspacePolicy,
      }));
      const errorMessage = this.validateWorkspacePolicyLimits(updatedWorkspace);
      if (errorMessage) {
        throw new Error(`${workspace.displayName}: ${errorMessage}`);
      }

      const instructions: jsonpatch.OpPatch[] = [
        {
          op: 'replace', path: '/members', value: members,
        },
      ];

      const progress = 0 + progressMultiplier * workspaces.indexOf(workspace);
      yield new ProgressUpdate(progress, `Removing member access in ${workspace.displayName}`);

      await this.adminApiService.patchWorkspace(instructions, workspace.workspaceId);
    }

    // Update/Add member access in workspaces
    progressMultiplier = 50 / workspaceAccesses.length;
    for (const workspaceAccess of workspaceAccesses) {
      let workspace = workspaces.find(w => w.workspaceId === workspaceAccess.workspaceId);

      if (!workspace) {
        // The workspace does not exist
        workspace = await this.getWorkspace(workspaceAccess.workspaceId);
      }

      const access = workspace.members.find(m => m.memberId === memberId);

      if (access && access.workspacePolicy === workspaceAccess.workspacePolicy) {
        // Do not update member access if it is the same
        continue;
      }

      const progress = 50 + progressMultiplier * workspaceAccesses.indexOf(workspaceAccess);
      yield new ProgressUpdate(progress, `Updating member access in ${workspace.displayName}`);

      const members = [
        ...workspace.members
          .filter(m => m.memberId !== memberId)
          .map(m => ({
            id: m.memberId,
            workspacePolicy: m.workspacePolicy,
          })),
        {
          id: memberId,
          workspacePolicy: workspaceAccess.workspacePolicy
        },
      ];

      // Validate workspace policy limits
      const updatedWorkspace = { ...workspace };
      updatedWorkspace.members = members.map(m => ({
        memberId: m.id,
        workspacePolicy: m.workspacePolicy,
      }));
      const errorMessage = this.validateWorkspacePolicyLimits(updatedWorkspace);
      if (errorMessage) {
        throw new Error(`${workspace.displayName}: ${errorMessage}`);
      }

      const instructions: jsonpatch.OpPatch[] = [
        {
          op: 'replace',
          path: '/members',
          value: members,
        },
      ];
      await this.adminApiService.patchWorkspace(instructions, workspace.workspaceId);
    }
  }

  validateWorkspacePolicyLimits(workspace: Workspace): string | null {
    if (!workspace.workspaceAdminLimit
      || workspace.workspaceAdminLimit === -1) {
      return null;
    }

    const adminCount = workspace.members
      .filter(m => m.workspacePolicy === LevelPolicy.admin).length;

    if (adminCount > workspace.workspaceAdminLimit) {
      return 'The workspace has the maximum number of Admin users.';
    }

    const standardCount = workspace.members
      .filter(m => m.workspacePolicy === LevelPolicy.standard).length;

    if (workspace.workspaceStandardLimit !== -1
      && standardCount > workspace.workspaceStandardLimit!) {
      return 'The workspace has the maximum number of Standard users.';
    }

    return null; // Standard limit is valid
  }

  /**
   * Get all workspaces in the platform
   * @returns All workspaces in the platform
   */
  async getWorkspaces(limit?: number, continuationToken?: string): Promise<[Workspace[], string]> {
    return await this.adminApiService
      .getWorkspaces(this.adminApiService.getPagingQueryString(limit, continuationToken))
      .then(([workspaces, continuationToken]) =>
        [workspaces.map((w) => this.convertWorkspaceDocumentToWorkspace(w)), continuationToken]);
  }

  /**
   * Get a workspace in the platform
   * @param workspaceId workspace id
   * @returns The workspace
   */
  async getWorkspace(workspaceId: string): Promise<Workspace> {
    const workspaceDocument = await this.adminApiService.getWorkspace(workspaceId);
    return this.convertWorkspaceDocumentToWorkspace(workspaceDocument);
  }

  /**
   * Update a workspace in the platform
   * @param workspace workspace to create
   */
  async updateWorkspace(workspace: Workspace): Promise<Workspace> {
    const workspaceDocument = this.convertWorkspaceToWorkspaceDocument(workspace);
    return this.convertWorkspaceDocumentToWorkspace(
      await this.adminApiService.updateWorkspace(workspaceDocument));
  }

  /**
   * Create default locations in a workspace
   * @param workspaceId workspace id
   */
  async createDefaultLocations(workspaceId: string): Promise<void> {
    const locations = ['London', 'Mumbai', 'Hong Kong', 'Johor', 'Haarlemweg', 'Singapore'];

    for (const location of locations) {
      const recordId = uuidv4();
      const locationRecord: Document = {
        id: recordId,
        documentId: recordId,
        documentType: 'record',
        content: {
          recordType: RecordTypes.location,
          fields: {
            subType: RecordTypes.location,
            state: 'draft',
            name: location,
            description: '-',
            postalCode: '-'
          },
          links: {}
        }
      };
      await this.documentApiService.createRecord(locationRecord, workspaceId);
    }
  }

  /**
   * Get all the workspaces that depend on the specified workspace.
   * @param workspaceId The workspace id
   * @returns The workspace ids that depend on the specified workspace and a boolean
   * indicating if at least one workspace could not be retrieved
   */
  async getDependentWorkspaces(workspaceId: string): Promise<[string[], boolean]> {
    const workspaceIds: string[] = [];
    let atLeast = false;
    const userMemberId = this.identityService.id();
    const [workspaces, _] = await this.adminApiService.getWorkspaces();
    for (const workspace of workspaces.filter(w => w.content.isEnabled)) {
      if (workspace.content.members.some(m => m.id == userMemberId && m.workspacePolicy !== '')) {
        try {
          const workspaceData = await this.documentApiService.getWorkspaceData(workspace.id);
          if (workspaceData.content.include && workspaceData.content.include
            .some((i: WorkspaceOperation) => i.id === workspaceId)) {
            workspaceIds.push(workspace.id);
          }
        } catch (e) {
          // Ignore errors
          console.error(`Workspace ${workspace.id}: ${e}`);
          atLeast = true;
        }
      } else {
        atLeast = true;
      }
    }
    return [workspaceIds, atLeast];
  }
}
