import { Injectable } from '@angular/core';
import { DocumentApiService } from './document-api.service';
import { Document, ProgressIterable, ProgressUpdate, Workspace, WorkspaceOperation } from '@core/models';
import { IdentityService } from './identity.service';
import { WorkspaceService } from './workspace.service';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceInheritanceService {

  constructor(
    private readonly documentApiService: DocumentApiService,
    private readonly identityService: IdentityService,
    private readonly workspaceService: WorkspaceService,
  ) { }

  async *buildWorkspace(workspaceId: string): ProgressIterable<void> {
    console.log('Building workspace', workspaceId);

    // Get the workspace data for the include operations
    const workspaceData = await this.documentApiService.getWorkspaceData(workspaceId);
    // Start with a blank slate
    const workingDocuments: Document[] = [];
    const workingRecordTypes: Document[] = [];

    const userMemberId = this.identityService.id();

    const operations: WorkspaceOperation[] = workspaceData.content.include;
    if (!operations) {
      return;
    }

    const multiplier = 50 / operations.length;
    for (const operation of operations) {

      // Check to make sure we have access to the template
      let template: Workspace | undefined = undefined;
      try {
        template = await this.workspaceService.getWorkspace(operation.id);
      } catch {
        throw new Error(`Template "${operation.id}" not found`);
      }
      
      if (!template) {
        throw new Error(`Template "${operation.id}" not found`);
      }

      if (!template.members.some(m => m.memberId === userMemberId && m.workspacePolicy)) {
        throw new Error(`You do not have access to template "${template.displayName}"`);
      }

      const defaultOp = operation.default || operation.op || '<none>';

      if (['replace', 'merge', 'skip'].indexOf(defaultOp) === -1) {
        throw new Error(`Unknown default: ${defaultOp} for template "${template.displayName}"`);
      }

      yield new ProgressUpdate(
        multiplier * operations.indexOf(operation),
        `Combining with template ${template.displayName}`);

      await this.combineWithTemplate(
        defaultOp,
        operation.exceptions,
        operation.id,
        workingDocuments,
        workingRecordTypes);
    }

    for await (const result of this.updateWorkspace(
      workspaceId,
      workingDocuments,
      workingRecordTypes)) {
        if (result instanceof ProgressUpdate) {
          yield new ProgressUpdate(50 + result.value * 0.5, result.message);
        }
      }
  }

  async combineWithTemplate(
    defaultOp: string,
    exceptions: { type: string; name?: string; language?: string; op: string; }[] | undefined,
    templateId: string,
    workingDocuments: Document[],
    workingRecordTypes: Document[],
  ): Promise<void> {

    const templateDocuments = await this.documentApiService.getAllDocuments(templateId);
    const templateRecordTypes = await this.documentApiService.getAllRecordTypes(templateId);

    for (const templateDocument of templateDocuments) {
      const exception = exceptions
        ?.find(e => e.type === templateDocument.documentType
          && (!e.name || e.name === templateDocument.name)
          && (!e.language || e.language === templateDocument.language));
      const op = exception?.op || defaultOp;

      if (['replace', 'merge', 'skip'].indexOf(op) === -1) {
        throw new Error(`Unknown operation: ${op} for template ${templateId}`
          + ` document type:${templateDocument.documentType}`
          + `, name:${templateDocument.name || '*'}`
          + `, language:${templateDocument.language || '*'}`);
      }

      if (op === 'skip') {
        continue;
      } else {
        const document = workingDocuments.find(d => d.name === templateDocument.name
          && d.documentType === templateDocument.documentType
          && d.language === templateDocument.language);

        if (document) {
          if (op === 'replace') {
            document.content = templateDocument.content;
          } else if (op === 'merge') {
            document.content = this.mergeDeep(document.content, templateDocument.content);
          }
        } else {
          const newDocument: Document = {
            id: '',
            documentId: '',
            documentType: templateDocument.documentType,
            name: templateDocument.name,
            language: templateDocument.language,
            content: { ...templateDocument.content },
          };
          if (newDocument.documentType === 'workspaceData') {
            // This is specific to a workspace, so remove it
            newDocument.content.info = {};
            newDocument.content.include = {};
          }
          workingDocuments.push(newDocument);
        }
      }
    }

    for (const templateRecordType of templateRecordTypes) {
      if (!templateRecordType.name) {
        continue;
      }

      const op = exceptions
      ?.find(e => e.type === templateRecordType.documentType
        && (!e.name || e.name === templateRecordType.name)
        && (!e.language || e.language === templateRecordType.language))?.op
      || defaultOp;

      if (['replace', 'merge', 'skip'].indexOf(op) === -1) {
        throw new Error(`Unknown operation: ${op} for template ${templateId}`
          + ` document type:${templateRecordType.documentType}`
          + `, name:${templateRecordType.name}`);
      }

      if (op === 'skip') {
        continue;
      } else {
        const recordType = workingRecordTypes.find(rt => rt.name === templateRecordType.name);

        if (recordType) {
          if (op === 'replace') {
            recordType.content = templateRecordType.content;
          } else if (op === 'merge') {
            recordType.content = this.mergeDeep(recordType.content, templateRecordType.content);
          }
        } else {
          const newRecordType: Document = {
            id: '',
            documentId: '',
            documentType: templateRecordType.documentType,
            name: templateRecordType.name,
            language: templateRecordType.language,
            content: { ...templateRecordType.content },
          };
          workingRecordTypes.push(newRecordType);
        }
      }
    }
  }

  async *updateWorkspace(
    workspaceId: string,
    documents: Document[],
    recordTypes: Document[]): ProgressIterable<void> {

    yield new ProgressUpdate(0, 'Updating workspace');

    // Get all the documents and record types in the workspace
    const workspaceDocuments: Document[] = await this.documentApiService.getAllDocuments(workspaceId);
    const workspaceRecordTypes: Document[] = await this.documentApiService.getAllRecordTypes(workspaceId);

    let multiplier = 50 / documents.length;
    for (const document of documents) {
      const workspaceDocument = workspaceDocuments.find(d => d.name === document.name
        && d.documentType === document.documentType
        && d.language === document.language);
      if (workspaceDocument) {
        document.id = workspaceDocument.id;
        document.documentId = workspaceDocument.documentId;
        if (document.documentType === 'workspaceData') {
          // This is specific to a workspace, so remove it
          document.content.info = workspaceDocument.content.info;
          document.content.include = workspaceDocument.content.include;
        }
        yield new ProgressUpdate(
          multiplier * documents.indexOf(document),
          `Updating document ${document.name || document.documentType}`);
        await this.documentApiService.updateDocument(document, workspaceId);
      } else {
        yield new ProgressUpdate(
          multiplier * documents.indexOf(document),
          `Creating document ${document.name || document.documentType}`);
        await this.documentApiService.createDocument(document, workspaceId);
      }
    }

    multiplier = 50 / recordTypes.length;
    for (const recordType of recordTypes) {
      const workspaceRecordType = workspaceRecordTypes.find(rt => rt.name === recordType.name);
      if (workspaceRecordType) {
        recordType.id = workspaceRecordType.id;
        recordType.documentId = workspaceRecordType.documentId;
        yield new ProgressUpdate(
          50 + multiplier * recordTypes.indexOf(recordType),
          `Updating record type ${recordType.name}`);
        await this.documentApiService.updateRecordType(recordType, workspaceId);
      } else {
        yield new ProgressUpdate(
          50 + multiplier * recordTypes.indexOf(recordType),
          `Creating record type ${recordType.name}`);
        await this.documentApiService.createRecordType(recordType, workspaceId);
      }
    }
  }

  private isObject(item: unknown) {
    return (item && typeof item === 'object' && !Array.isArray(item));
  }

  /**
   * Deep merge two objects.
   * @param target
   * @param ...sources
   */
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  mergeDeep(target: any, ...sources: any[]): any {
    if (!sources.length) return target;
    const source = sources.shift();

    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) Object.assign(target, { [key]: {} });
          this.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }

    return this.mergeDeep(target, ...sources);
  }

}
