import { signal, WritableSignal } from '@angular/core';
import { SortDirection } from '@angular/material/sort';

export interface MemberAccess {
  memberId: string;
  workspacePolicy: string;
}

export interface WorkspaceAccess {
  workspaceId: string;
  workspacePolicy: string;
}

export interface Member {
  memberId: string;
  objectId: string;
  name: string;
  email: string;
  platformPolicy: string;
  isServiceMember?: boolean;
  secret?: string;
  tags?: string[];
}

export interface MemberAccess {
  memberId: string;
  workspacePolicy: string;
}

export interface Workspace {
  workspaceId: string;
  displayName: string;
  uriName: string;
  members: MemberAccess[];
  description?: string;
  templateId?: string;
  isEnabled?: boolean;
  isReference?: boolean;
  workspaceAdminLimit?: number;
  workspaceStandardLimit?: number;
  publicWorkspacePolicy?: string;
  ownerMemberId: string;
  tags?: string[];
}

export interface SortState {
  active: string;
  direction: SortDirection;
  disableClear: boolean;
}

export interface WorkspaceOperation {
  id: string;
  default: string; // 'replace' | 'merge'
  op?: string; // obsolite, but 'replace' | 'merge'
  exceptions?: {
    type: string;
    name?: string;
    language?: string;
    op: string; // 'replace' | 'merge' | 'skip' | 'delete'
  }[];
}

export class ProgressUpdate {
  constructor(public value: number, public message: string, public isSuccess = true) {
  }
}

export class ProgressState {
  public updates: WritableSignal<ProgressUpdate>;
  constructor(initialMessage: string, public isSuccess = true) {
   this.updates = signal(new ProgressUpdate(0, initialMessage));
  }

  update(value: number | ProgressUpdate, message?: string) {
    if (typeof value === 'number') {
      this.updates.set(new ProgressUpdate(value, message || ''));
      return;
    }
    this.updates.set(value);
  }

  complete(message: string) {
    this.updates.set(new ProgressUpdate(100, message));
  }

  error(message: string) {
    this.updates.set(new ProgressUpdate(100, message, false));
  }
}

export type ProgressIterable<T> = AsyncIterable<ProgressUpdate | T>;

export interface SnackBarData {
  message: string;
  action?: string;
}
