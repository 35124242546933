import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatSnackBarLabel, MatSnackBarActions, MatSnackBarAction, MAT_SNACK_BAR_DATA, MatSnackBarRef
} from '@angular/material/snack-bar';
import { SnackBarData } from '@core/models';

@Component({
  selector: 'app-exception-snackbar',
  standalone: true,
  imports: [MatButtonModule, MatSnackBarLabel, MatSnackBarActions, MatSnackBarAction],
  templateUrl: './exception-snackbar.component.html',
  styleUrl: './exception-snackbar.component.scss'
})
export class ExceptionSnackbarComponent {
  data = inject<SnackBarData>(MAT_SNACK_BAR_DATA);
  snackBarRef = inject(MatSnackBarRef);
}
