import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { ErrorSnackbarComponent, ExceptionSnackbarComponent } from '@shared/snackbars';

export class Toast {
  constructor (private ref: MatSnackBarRef<TextOnlySnackBar>) {}

  close() {
    this.ref.dismiss();
  }
}


@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private snackBar: MatSnackBar) { }

  open(message: string): Toast {
    return new Toast(this.snackBar.open(message));
  }

  pop(message: string): void {
    this.snackBar.open(message, undefined, { duration: 6000 });
  }

  error(message: string): void {
    this.snackBar.openFromComponent(
      ErrorSnackbarComponent,
      {
        duration: 6000,
        data: { message },
      });
  }

  exception(e: unknown, message: string): void {
    console.error(e);
    this.snackBar.openFromComponent(
      ExceptionSnackbarComponent,
      { data: { message } });
  }
}
