import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Document } from '@core/models';
import { Observable, firstValueFrom, map } from 'rxjs';
import yaml from 'js-yaml';

export enum Library {
  defaultEmailTemplate = 'common/default-email-template.yaml',
  defaultRecordType = 'common/default-record-type.yaml',
  defaultRecordTypeTranslation = 'common/default-record-type-en-gb-translation.yaml',
  defaultDashboard = 'common/default-dashboard.yaml',
  defaultDashboardTranslation = 'common/default-dashboard-en-gb-translation.yaml',
  defaultReferenceData = 'common/default-reference-data.yaml',
  defaultReferenceDataTranslationEnGB = 'common/default-reference-data-en-gb-translation.yaml',
  defaultReferenceDataTranslationEsES = 'common/default-reference-data-es-es-translation.yaml',
  defaultReferenceDataTranslationHiIN = 'common/default-reference-data-hi-in-translation.yaml',
  defaultTranslation = 'common/default-translation.yaml',
  defaultTermsOfUse = 'common/default-terms-of-use.yaml',
  defaultUserData = 'common/default-user-data.yaml',
  defaultWorkspaceData = 'common/default-workspace-data.yaml',
  globalTranslation = 'common/global-en-gb-translation.yaml',
  locationRecordType = 'common/location-type.yaml',
  locationTranslation = 'common/location-en-gb-translation.yaml',
  personRecordType = 'common/person-type.yaml',
  personTranslation = 'common/person-en-gb-translation.yaml',
  recordTypeTranslation = 'common/record-type-en-gb-translation.yaml',
  starterRecordRecordType = 'starter/record-type.yaml',
  starterRecordTranslation = 'starter/record-en-gb-translation.yaml',
  starterReferenceData = 'starter/reference-data.yaml',
  starterReferenceDataTranslation = 'starter/reference-en-gb-translation.yaml',
  starterHomePageDocument = 'starter/home-page-document.yaml',
  starterHomePageTranslation = 'starter/home-page-en-gb-translation.yaml',
  dashboardContentSchema = 'schemas/dashboard-content-schema.yaml',
  documentSchema = 'schemas/document-schema.yaml',
  recordContentSchema = 'schemas/record-content-schema.yaml',
  recordTypeContentSchema = 'schemas/record-type-content-schema.yaml',
  referenceDataContentSchema = 'schemas/reference-data-content-schema.yaml',
  translationContentSchema = 'schemas/translation-content-schema.yaml',
  starterWorkspaceDataDocument = 'starter/workspace-data-document.yaml',
  defaultUserDataDocument = 'common/user-data-document.yaml',
  termsOfUseContentSchema = 'schemas/terms-of-use-content-schema.yaml',
  emailTemplateContentSchema = 'schemas/email-template-content-schema.yaml',
  baseWorkspaceData = 'common/base-workspace-data.yaml',

  smartAuditV2AuditRecordType = 'smart-audit-v2/audit-type.yaml',
  smartAuditV2AuditTranslation = 'smart-audit-v2/audit-en-gb-translation.yaml',
  smartAuditV2ChecklistRecordType = 'smart-audit-v2/checklist-type.yaml',
  smartAuditV2ChecklistTranslation = 'smart-audit-v2/checklist-en-gb-translation.yaml',
  smartAuditV2FindingRecordType = 'smart-audit-v2/finding-type.yaml',
  smartAuditV2FindingTranslation = 'smart-audit-v2/finding-en-gb-translation.yaml',
  smartAuditV2GlobalTranslation = 'smart-audit-v2/global-en-gb-translation.yaml',
  smartAuditV2HomePageDocument = 'smart-audit-v2/home-page-document.yaml',
  smartAuditV2HomePageTranslation = 'smart-audit-v2/home-page-en-gb-translation.yaml',
  smartAuditV2LocationRecordType = 'smart-audit-v2/location-type.yaml',
  smartAuditV2LocationTranslation = 'smart-audit-v2/location-en-gb-translation.yaml',
  smartAuditV2PersonRecordType = 'smart-audit-v2/person-type.yaml',
  smartAuditV2PersonTranslation = 'smart-audit-v2/person-en-gb-translation.yaml',
  smartAuditV2RecordTypeTranslation = 'smart-audit-v2/record-type-en-gb-translation.yaml',
  smartAuditV2ReferenceData = 'smart-audit-v2/reference-data-document.yaml',
  smartAuditV2ReferenceDataTranslation = 'smart-audit-v2/reference-data-en-gb-translation.yaml',
  smartAuditV2WorkspaceDataDocument = 'smart-audit-v2/workspace-data-document.yaml',

  automationActionRecordType = 'automation/action-type.yaml',
  automationActionTranslation = 'automation/action-en-gb-translation.yaml',
  automationAuditRecordType = 'automation/audit-type.yaml',
  automationAuditTranslation = 'automation/audit-en-gb-translation.yaml',
  automationChecklistRecordType = 'automation/checklist-type.yaml',
  automationChecklistTranslation = 'automation/checklist-en-gb-translation.yaml',
  automationFindingRecordType = 'automation/finding-type.yaml',
  automationFindingTranslation = 'automation/finding-en-gb-translation.yaml',
  automationGlobalTranslation = 'automation/global-en-gb-translation.yaml',
  automationHomePageDocument = 'automation/home-page-document.yaml',
  automationHomePageTranslation = 'automation/home-page-en-gb-translation.yaml',
  automationLocationRecordType = 'automation/location-type.yaml',
  automationLocationTranslation = 'automation/location-en-gb-translation.yaml',
  automationPersonRecordType = 'automation/person-type.yaml',
  automationPersonTranslation = 'automation/person-en-gb-translation.yaml',
  automationRecordTypeTranslation = 'automation/record-type-en-gb-translation.yaml',
  automationReferenceData = 'automation/reference-data-document.yaml',
  automationReferenceDataTranslation = 'automation/reference-data-en-gb-translation.yaml',
  automationWorkspaceDataDocument = 'automation/workspace-data-document.yaml',

  takeawayAuditRecordType = 'takeaway/audit-type.yaml',
  takeawayAuditTranslation = 'takeaway/audit-en-gb-translation.yaml',
  takeawayChecklistRecordType = 'takeaway/checklist-type.yaml',
  takeawayChecklistTranslation = 'takeaway/checklist-en-gb-translation.yaml',
  takeawayFindingRecordType = 'takeaway/finding-type.yaml',
  takeawayFindingTranslation = 'takeaway/finding-en-gb-translation.yaml',
  takeawayGlobalTranslation = 'takeaway/global-en-gb-translation.yaml',
  takeawayHomePageDocument = 'takeaway/home-page-document.yaml',
  takeawayHomePageTranslation = 'takeaway/home-page-en-gb-translation.yaml',
  takeawayLocationRecordType = 'takeaway/location-type.yaml',
  takeawayLocationTranslation = 'takeaway/location-en-gb-translation.yaml',
  takeawayPersonRecordType = 'takeaway/person-type.yaml',
  takeawayPersonTranslation = 'takeaway/person-en-gb-translation.yaml',
  takeawayRecordTypeTranslation = 'takeaway/record-type-en-gb-translation.yaml',
  takeawayReferenceData = 'takeaway/reference-data-document.yaml',
  takeawayReferenceDataTranslation = 'takeaway/reference-data-en-gb-translation.yaml',
  takeawayWorkspaceDataDocument = 'takeaway/workspace-data-document.yaml',
  takeawayTermsOfUseDocument = 'takeaway/terms-of-use-document.yaml',

  plusPremiumActionRecordType = 'plus-premium/action-type.yaml',
  plusPremiumActionTranslation = 'plus-premium/action-en-gb-translation.yaml',
  plusPremiumAuditRecordType = 'plus-premium/audit-type.yaml',
  plusPremiumAuditTranslation = 'plus-premium/audit-en-gb-translation.yaml',
  plusPremiumChecklistRecordType = 'plus-premium/checklist-type.yaml',
  plusPremiumChecklistTranslation = 'plus-premium/checklist-en-gb-translation.yaml',
  plusPremiumFindingRecordType = 'plus-premium/finding-type.yaml',
  plusPremiumFindingTranslation = 'plus-premium/finding-en-gb-translation.yaml',
  plusPremiumGlobalTranslation = 'plus-premium/global-en-gb-translation.yaml',
  plusPremiumHomePageDocument = 'plus-premium/home-page-document.yaml',
  plusPremiumHomePageTranslation = 'plus-premium/home-page-en-gb-translation.yaml',
  plusPremiumLocationRecordType = 'plus-premium/location-type.yaml',
  plusPremiumLocationTranslation = 'plus-premium/location-en-gb-translation.yaml',
  plusPremiumPersonRecordType = 'plus-premium/person-type.yaml',
  plusPremiumPersonTranslation = 'plus-premium/person-en-gb-translation.yaml',
  plusPremiumRecordTypeTranslation = 'plus-premium/record-type-en-gb-translation.yaml',
  plusPremiumReferenceData = 'plus-premium/reference-data-document.yaml',
  plusPremiumReferenceDataTranslation = 'plus-premium/reference-data-en-gb-translation.yaml',
  plusPremiumWorkspaceDataDocument = 'plus-premium/workspace-data-document.yaml',
  plusPremiumTermsOfUseDocument = 'plus-premium/terms-of-use-document.yaml',

  integrationFindingRecordType = 'integration/finding-type.yaml',
  integrationFindingTranslation = 'integration/finding-en-gb-translation.yaml',
  integrationReferenceDataDocument = 'integration/reference-data-document.yaml',
  integrationReferenceDataTranslation = 'integration/reference-data-en-gb-translation.yaml',
  integrationRecordType = 'integration/integration-type.yaml',
  integrationTranslation = 'integration/integration-en-gb-translation.yaml',
  integrationWorkspaceRecordType = 'integration/workspace-type.yaml',
  integrationWorkspaceDataDocument = 'integration/workspace-data-document.yaml',
  integrationWorkspaceTranslation = 'integration/workspace-en-gb-translation.yaml',
  serviceAccountRecordType = 'common/service-account-type.yaml',
  serviceAccountTranslation = 'common/service-account-en-gb-translation.yaml',
  plusLegacyRecordRecordType = 'plus-legacy/record-type.yaml',
  plusLegacyRecordTranslation = 'plus-legacy/record-en-gb-translation.yaml',
  plusLegacyReferenceData = 'plus-legacy/reference-data.yaml',
  plusLegacyReferenceDataTranslation = 'plus-legacy/reference-en-gb-translation.yaml',
  plusLegacyHomePageDocument = 'plus-legacy/home-page-document.yaml',
  plusLegacyHomePageTranslation = 'plus-legacy/home-page-en-gb-translation.yaml',
  plusLegacyWorkspaceDataDocument = 'plus-legacy/workspace-data-document.yaml',
  plusLegacyIntegrationRecordType = 'plus-legacy/integration-type.yaml',
  plusLegacyIntegrationTranslation = 'plus-legacy/integration-en-gb-translation.yaml',
  plusLegacyLocationRecordType = 'plus-legacy/location-type.yaml',
  plusLegacyLocationTranslation = 'plus-legacy/location-en-gb-translation.yaml',
  plusLegacyPersonRecordType = 'plus-legacy/person-type.yaml',
  plusLegacyPersonTranslation = 'plus-legacy/person-en-gb-translation.yaml',
  plusLegacyTermsOfUseDocument = 'plus-legacy/terms-of-use-document.yaml',
}

@Injectable({
  providedIn: 'root'
})
export class LibraryService {
  private baseUri = '/assets/library/';
  private options = { responseType: 'text' as 'json' };

  constructor(private http: HttpClient) { }

  getItem$(item: Library): Observable<Document> {
    return this.http.get<string>(this.baseUri + item, this.options)
      .pipe(
        map((response) => (yaml.load(response) as Document)),
      );
  }

  async getItem(item: Library): Promise<Document> {
    return await firstValueFrom(
      this.http.get<string>(this.baseUri + item, this.options)
        .pipe(
          map((response) => (yaml.load(response) as Document)),
        ));
  }
}
